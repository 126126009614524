$( document ).ready( function() {
    $( '.card-show-more-button' ).click( function( e ) {
        var wrapper = $( this ).siblings( '.show-more-card-wrapper' );
        var cards = $( wrapper ).find( '.show-more-card-element' );
        $( cards ).toggleClass( 'show-more-card-element-active' );

        var textReference = $( this ).find( 'span' );
        var iconReference = $( this ).find( 'img' );
        if ( textReference.text() == "SHOW MORE" ) {
            textReference.text( "SHOW LESS" );
        } else {
            textReference.text( "SHOW MORE" );
            this.scrollIntoView();
        }
        iconReference.toggleClass( 'cm-rotate-show-more-icon' );
    } );
} );